<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full" id="overtimestat">
      <vs-table class="w-full" ref="table" search :data="calendarStat">
        <template slot="thead">
          <vs-th sort-key="name">이름</vs-th>
          <vs-th sort-key="entry_date">입사일</vs-th>
          <vs-th sort-key="resetDate">휴가기준일</vs-th>
          <vs-th sort-key="max_timeoff">총휴가일수</vs-th>
          <vs-th sort-key="usedTimeoff">휴가사용일수 (~기준일)</vs-th>
          <vs-th sort-key="remainTimeoff">잔여휴가일수 (~기준일)</vs-th>
          <vs-th sort-key="usedTimeoff2">휴가사용일수2 (~해당년 전체)</vs-th>
          <vs-th sort-key="remainTimeoff2">잔여휴가일수2 (~해당년 전체)</vs-th>
        </template>

          <template slot-scope="{data}">
            <tbody>
              <!-- <vs-tr :style="[tr.level >= 9 ? {'background-color': '#CCFFCC' } : tr.level >= 8 ? {'background-color': '#FFFFCC' } : {'background-color': '#DDCCFF'}]" :data="tr" :key="indextr" v-for="(tr, indextr) in data"> -->
              <vs-tr :style="[tr.level >= 9 ? {'background-color': '#ddFFdd' } : {'background-color': '#eeddFF'}]" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ tr.name }}</vs-td>
                <vs-td>{{ tr.entry_date }}</vs-td>
                <vs-td>{{ tr.startDate }}</vs-td>
                <vs-td>{{ tr.max_timeoff }}</vs-td>
                <vs-td>{{ tr.usedTimeoff }}</vs-td>
                <vs-td>{{ tr.remainTimeoff }}</vs-td>
                <vs-td>{{ tr.usedTimeoff2 }}</vs-td>
                <vs-td>{{ tr.remainTimeoff2 }}</vs-td>
              </vs-tr>
            </tbody>
          </template>
      </vs-table>
    </div>
</template>

<script>
// import axios from 'axios'
import moment from 'moment'
export default {
  data() {
    return {
      calendarStat: [{}],
      writerOption: [{}],
      target: '',
    }
  },
  methods: {
  },
  created() {
    const thisIns = this;
    const target = moment(this.$route.query.target);
    // const target2 = target.clone().add(-1, 'y');
    this.target = target.format('YYYY-MM-DD');
    this.$http.post('/api/getVarList/writer', {})
      .then(function (response) {
        console.log(response)
        thisIns.writerOption = response.data;
        thisIns.writerOption = thisIns.writerOption.filter(e => moment(e.entry_date) > moment('2000-01-01'));
        for(let i in thisIns.writerOption){
          const resetDate = moment(thisIns.writerOption[i].entry_date).year(moment().format('YYYY'))
          if(moment() > resetDate){
            thisIns.writerOption[i].resetDate = resetDate.format('YYYY-MM-DD')
          }else{
            thisIns.writerOption[i].resetDate = resetDate.subtract(1,'year').format('YYYY-MM-DD')
          }
          thisIns.writerOption[i].entry_date = moment(thisIns.writerOption[i].entry_date).format('YYYY-MM-DD')
          // console.log(thisIns.writerOption[i].entry_date)
        }
        thisIns.calendarStat = JSON.parse(JSON.stringify(thisIns.writerOption));
        return thisIns.$http.post('/api/board/getTimeOffListYear', {data: target})
      }).then(function (response) {
        // console.log(response)
        for(let writer of thisIns.calendarStat){
          const entryDate = writer.entry_date;
          const resetDate = moment(entryDate).year(target.format('YYYY'))
          if(target > resetDate){
            writer.startDate = resetDate.format('YYYY-MM-DD');
            writer.endDate = resetDate.add(1, 'year').format('YYYY-MM-DD');
          }else{
            writer.endDate = resetDate.format('YYYY-MM-DD');
            writer.startDate = resetDate.subtract(1,'year').format('YYYY-MM-DD');
          }
          if(!writer.max_timeoff){
            writer.max_timeoff = 15;
          }else{
            writer.max_timeoff += 15;
          }
          let contYear = target.diff(moment(writer.entry_date), 'year');
          if(target < moment(writer.entry_date)){
            writer.max_timeoff = 0;
          }else if(contYear == 0){
            writer.max_timeoff = target.diff(moment(writer.entry_date), 'month');
          }else{
            writer.max_timeoff = writer.max_timeoff + parseInt((contYear - 1) / 2);
          }
          if(writer.max_timeoff > 25){
            writer.max_timeoff = 25;
          }
          writer.usedTimeoff = 0;
          writer.remainTimeoff = writer.max_timeoff;
          writer.usedTimeoff2 = 0;
          writer.remainTimeoff2 = writer.max_timeoff;
          // for(let timeoff of response.data.filter(e => e.writer == writer.name && moment(e.start) >= moment(writer.startDate) && moment(e.start) < moment(writer.endDate))){
          for(let timeoff of response.data.filter(e => e.writer == writer.name && moment(e.start) >= moment(writer.startDate) && moment(e.start) < target)){
            // console.log(timeoff, writer.startDate, writer.endDate)
            if(timeoff.state >= 0){
              if(timeoff.factor == 1){
                const days =  moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day')
                writer.usedTimeoff += days;
                writer.remainTimeoff -= days;
              }else if(timeoff.factor){
                const days =  moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day')
                writer.usedTimeoff += days * timeoff.factor;
                writer.remainTimeoff -= days * timeoff.factor;
              }
            }
          }
          
          for(let timeoff of response.data.filter(e => e.writer == writer.name && moment(e.start) >= moment(writer.startDate) && moment(e.start) < moment(writer.endDate))){
            if(timeoff.state >= 0){
              if(timeoff.factor == 1){
                const days =  moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day')
                writer.usedTimeoff2 += days;
                writer.remainTimeoff2 -= days;
              }else if(timeoff.factor){
                const days =  moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day')
                writer.usedTimeoff2 += days * timeoff.factor;
                writer.remainTimeoff2 -= days * timeoff.factor;
              }
            }
          }
        }
        thisIns.calendarStat.sort((a,b) => b.level - a.level);
      }).catch(function (error) {
        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
        thisIns.$vs.notify({
          title:'Error',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'})
        thisIns.$vs.loading.close(); 
      });   
  }
}
</script>
